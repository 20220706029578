:root {
    --circle-width: calc(90vmin - 10px);
    --circle-height: calc(90vmin - 10px);
}
.frame {
    width: 100vw;
    height: 100vh;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    overflow-scrolling: touch;
}
.circle-window {
    margin-top: calc((100vh - var(--circle-width)) / 2);
    margin-bottom: calc((100vh - var(--circle-width)) / 2);
    height: var(--circle-height);
    width: 100vw;
    left: 0;
    top: 0;
    position: absolute;

}
.bottom-gap {
    height: calc((100vh - var(--circle-width)) / 2);
}

.frame-image-top {
    pointer-events: none;
    z-index: 997;
    position: fixed;
    bottom: 50vh;
    right: 50vw;
    transform: translateX(calc(-42vw + 225px)) translateY(calc(-42vh + 244.5px));
    width: 550px;
    height: 489px;
    background: url('/public/assets/images/flower-1.png'), #ff010100 100% 100% / 80% no-repeat;
}

.frame-image-bottom {
    pointer-events: none;
    z-index: 997;
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translateX(calc(42vw - 225px)) translateY(calc(42vh - 244.5px));
    width: 550px;
    height: 489px;
    background: url('/public/assets/images/flower-1.png'), #ff010100 100% / cover no-repeat;
}


.scroll-guide-frame {
    left:0;
    right: 0;
    margin: auto;
    bottom: 5px;
    position: absolute;
    padding: 3px 21px;
    border-radius: 31px;
    border: 1px solid var(--color-primary);
    background: var(--color-acent);
    width: 70vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    height: min-content;
    z-index: 999;
}
.bg-color {
    background: var(--color-background);
}

.scrollable {
    overflow-y: auto;
    height: 100vh;
    width: 100vw;
    overscroll-behavior: contain;
    background: var(--color-background);
    mask: radial-gradient(circle at 50% 50%, var(--color-background) 38%, rgba(251,247,211,0) 38%, rgba(255,255,255,0) 100%);
    mask-repeat: no-repeat;
    scroll-snap-type: y mandatory;
}
.bg-color:before {
    content: "";
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: radial-gradient(circle at 50% 50%,#efefef 68%, var(--color-secondary) 100%);
}

#Confirmation {
    background: linear-gradient(var(--color-background) 0%, var(--color-secondary) 28%, var(--color-secondary) 100%);
}

.finalMessage {
    position: fixed;
    left: 0;
    right: 0;
    bottom: calc(((100vh - var(--circle-height)) / 2) - 70px);
    margin: auto;
    z-index: 998;
    background: var(--color-background);
    border-radius: 10px;
    border: 2px dashed   var(--color-primary-lite);
    width: var(--circle-width);
}

#rescind-rsvp {
    position: fixed;
    left: 15px;
    bottom: 15px;
    border: 2px solid var(--color-primary-lite);
    background: var(--color-background);
    border-radius: 25px;
    z-index: 997;
}

#rescind-rsvp > a{
    text-decoration: none;
    color: inherit;
}