

#honor-caption {
    margin-top: 30px;
    margin-bottom: 30px;
}
button {
    background: var(--color-acent);
    border-radius: 30px;
    padding: 5px 20px;
    cursor: pointer;
}