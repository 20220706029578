section {
    width: 100%;
    height: 100%;
    position: relative;
    scroll-snap-align: center;
}
.center {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: max-content;
    height: min-content;
    margin: auto;
    position: absolute;
}