#location-name {
    margin-top: 10px;
    margin-bottom: 30px;
}
#location-room {
    margin-top: 10px;
}

.clickable {
    cursor: pointer;
}