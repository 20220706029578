@import url('https://fonts.googleapis.com/css?family=Baloo+Bhaijaan');
@import url('https://fonts.googleapis.com/css?family=Boogaloo');
@import url('https://fonts.googleapis.com/css?family=Oooh+Baby');
@import url('https://fonts.googleapis.com/css?family=Anybody');
@import url('https://fonts.googleapis.com/css?family=Gabriela');
:root {
  --color-primary: #321836;
  --color-primary-lite: #AC4C89;
  --color-background: #f7fcfa;
  --color-acent: #FBF7D3;
  --color-secondary: #FEBECC;
}
html {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p {
  margin: 0;
}
.font-caption {
  font-family: Anybody, serif;
  font-size: 6vmin;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
.font-caption-large {
  font-family: Baloo Bhaijaan, serif;
  font-size: 18vmin;
  font-style: normal;
  font-weight: 400;
  line-height: 85.7%; /* 82.272px */
  letter-spacing: -3.36px;
  text-transform: uppercase;
  container-name: font-caption-large-scaled
}
.font-secondary-small{
  font-family: Boogaloo, serif;
  font-size: 3vmin;
  font-style: normal;
  font-weight: 400;
  line-height: 86.7%; /* 41.616px */
  letter-spacing: 1.1px;
  text-transform: capitalize;
}
.font-secondary{
  font-family: Boogaloo, serif;
  font-size: 6vmin;
  font-style: normal;
  font-weight: 400;
  line-height: 86.7%; /* 41.616px */
  letter-spacing: -0.96px;
  text-transform: uppercase;
}
.font-secondary-large {
  font-family: Boogaloo, serif;
  font-size: 14vmin;
  font-style: normal;
  font-weight: 400;
  line-height: 86.7%; /* 65.025px */
  letter-spacing: -1.5px;
  text-transform: capitalize;
}
.font-stylist{
  font-family: Oooh Baby, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -2.34px;
}
.font-baby-name{
  font-family: Gabriela, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -2.6px;
}
.font-color-accent {
  color: var(--color-acent);
}
.font-color-primary {
  color: var(--color-primary);
}
.font-color-secondary {
  color: var(--color-secondary);
  -webkit-text-stroke: .2px var(--color-primary-lite);
}
.font-color-primary {
  color: var(--color-primary);
  -webkit-text-stroke: .2px var(--color-primary-lite);
}