.rsvp-form {
    width: var(--circle-width);
    height: max-content;
}

form {
    display: flex;
    flex-direction: column;
}
#submitter {
    width: 62%;
}
input {
    background: var(--color-secondary);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 25px;
    height: 2.5em;
    padding-left: 10px;
    margin-bottom: 5px;
}

form div input {
    width: 54%;
}

button {
    background: var(--color-secondary);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 30px;
    padding: 5px 20px;
    cursor: pointer;
}
.circleAction {
    margin-left: 5px;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    padding: 2px;
}
.left {
    align-content: flex-start;
    display: flex;
    left: 0;
    right:0;
    margin: auto;
    width: 68%;
    gap: 5px;
}
.submitButton {
    width: min-content;
    padding: 5px 10px;
    left: 0;
    right:0;
    margin: 10px auto auto;
}
button[disabled] {
    cursor: not-allowed;
    background: #d2d2d2;
}

.MuiCircularProgress-root {
    height: calc(var(--circle-height) * .8) !important;
    width: calc(var(--circle-width) * .8) !important;
}